<template>
  <v-app>
    <Modal />

    <v-container
      style="height: 100vh"
      class="pa-0 primary-gradient d-flex align-center justify-center"
      fluid
    >
      <v-card
        width="300"
        rounded="xl"
        elevation="10"
      >
        <v-card-title class="flex-column">
          <v-img
            src="@/assets/logo.png"
            max-width="70"
          />
          <h6 class="text-h6 primary--text">Faça seu login</h6>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login">
            <v-text-field
              v-model="user.email"
              label="Usuário"
              rounded
              outlined
              append-icon="fas fa-user"
            />
            <v-text-field
              v-model="user.password"
              label="Senha"
              type="password"
              rounded
              outlined
              append-icon="fas fa-lock"
            />
            <v-btn
              rounded
              block
              large
              :loading="isLoading"
              type="submit"
              color="primary"
              class="mb-5"
            >
              Entrar
            </v-btn>
            <v-btn
              rounded
              block
              small
              text
              color="primary"
              @click="$router.push('/')"
            >
              <v-icon left> fas fa-arrow-left </v-icon>
              Voltar para o site
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Modal from '@/components/modal/Modal'

export default {
  components: {
    Modal,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isLoading: false,
    }
  },
  methods: {
    redirectToRoute(type) {
      switch (type) {
        case 'master':
          this.$router.push({ name: 'stockDashboard' })
          break
        case 'admin':
          this.$router.push({ name: 'stock-dashboard' })
          break
        case 'seller':
          this.$router.push({ name: 'people-dashboard' })
          break
        case 'editor':
          this.$router.push({ name: 'siteBanners' })
          break
        case 'production':
          this.$router.push({ name: 'order-products' })
          break
      }
    },
    login() {
      this.isLoading = true

      this.$api
        .post('auth/login', this.user)
        .then((res) => {
          this.$session.start()
          this.$cookies.set('token', res.data.token)
          this.$cookies.set('isAuth', res.data.user.id)
          this.$cookies.set('name', res.data.user.name)
          this.$cookies.set('profile', res.data.profile)
          this.$cookies.set('type', res.data.type)
          this.$cookies.set('id', res.data.id)

          this.redirectToRoute(res.data.type)
        })
        .catch((err) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Algo deu errado',
            caption: err.response.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
